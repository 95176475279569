.text-custom-green {
    color: #008D6C;
}

.text-custom-green:hover {
    color: #00755A;
}

.header-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: white;
    z-index: 1000;
}
