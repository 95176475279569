@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


@font-face {
  font-family: 'Quicksand-Regular';
  src: url(./assets/fonts/Quicksand-Regular.ttf);
}

@font-face {
  font-family: 'Quicksand-Bold';
  src: url(./assets/fonts/Quicksand-Bold.ttf);
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Quicksand-Regular';
}